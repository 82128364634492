export const CREATE_POST = {
    key: 'create-post',
    label: 'Create (Shift + N)',
    href: '/create/custom',
    icon: 'material-symbols:add-notes-rounded',
}

export const POSTS = {
    label: 'Posts',
    href: '/posts',
    icon: 'material-symbols:calendar-view-day-rounded',
}

export const CALENDAR = {
    label: 'Calendar',
    href: '/calendar',
    icon: 'solar:calendar-bold',
}

export const CONNECT = {
    label: 'Connect',
    href: '/connections',
    icon: 'ion:extension-puzzle',
}

export const WATERMARKS = {
    label: 'Watermarks',
    href: '/watermarks',
    icon: 'material-symbols:branding-watermark-outline-rounded',
}

export const TEMPLATES = {
    label: 'Templates',
    href: '/templates',
    icon: 'mingcute:report-forms-fill',
}

export const SIGNATURES = {
    label: 'Signatures',
    href: '/signatures',
    icon: 'ph:signature-fill',
}

export const HASHTAG_LISTS = {
    label: 'Hashtag lists',
    href: '/hashtag-lists',
    icon: 'mingcute:hashtag-fill',
}

export const SETTINGS = {
    label: 'Settings',
    href: '/settings/workspace',
    icon: 'material-symbols:settings-rounded',
}

export const WORKSPACE = {
    label: 'Workspace',
    href: '/settings/workspace',
    icon: 'material-symbols:workspaces',
    group: 'settings',
}

export const ACCOUNT = {
    label: 'Account',
    href: '/settings/account',
    icon: 'material-symbols:account-circle',
    group: 'settings',
}

export const SECURITY = {
    label: 'Security',
    href: '/settings/security',
    icon: 'material-symbols:security',
    group: 'settings',
}

export const BILLING = {
    label: 'Billing',
    href: '/settings/billing',
    icon: 'material-symbols:credit-card',
    group: 'billing',
}

export const BROWSER_EXTENSION = {
    label: 'Browser Extension',
    href: '/settings/browser-extension',
    icon: 'material-symbols:extension',
    group: 'external',
}

export const MOBILE_APP = {
    label: 'Mobile App',
    href: '/settings/mobile-app',
    icon: 'material-symbols:smartphone',
    //icon: 'material-symbols:phone-android',
    group: 'external',
}
